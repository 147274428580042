<template>
  <div>
    <h2>Recruitments</h2>
    <employee-filter @filter-change="handleFilterChange" />
    <a-table :dataSource="recruitments" :columns="columns" rowKey="RecruitID">
      <template slot-scope="text, record" slot="oneMonth">
        <a-checkbox v-model="text['1-month'].paid" @change="updateRecruitmentStatus($event, record.id, '1-month')" v-if="!Boolean(text['1-month'].date)"/>
        <div v-else>Registrert:<br>{{ moment(text['1-month'].date).format('DD.MM.YYYY') }}</div>
      </template>
      <template slot="budget" slot-scope="text, record">
        <a-checkbox v-model="text.budget.paid" @change="updateRecruitmentStatus($event, record.id, 'budget')" v-if="!Boolean(text.budget.date)"/>
        <div v-else>Registrert:<br>{{ moment(text.budget.date).format('DD.MM.YYYY') }}</div>
      </template>
      <template slot="threeMonth" slot-scope="text, record">
        <a-checkbox v-model="text['3-month'].paid" @change="updateRecruitmentStatus($event, record.id, '3-month')" v-if="!Boolean(text['3-month'].date)"/>
        <div v-else>Registrert:<br>{{ moment(text['3-month'].date).format('DD.MM.YYYY') }}</div>
      </template>
      <template slot="delete" slot-scope="text, record">
        <a-button @click="deleteRecruitment(record.id)"><i class="fa fa-trash"></i></a-button>
      </template>
    </a-table>
    <a-divider />
    <div v-if="toBonus.length > 0" class="mt-3">
      <a-card title="Rekrutteringsbonuser" bordered>
        <ul>
          <li v-for="(bonus, index) in toBonus" :key="index">
            {{ bonus.employeeName }}: {{ bonus.comment }} - {{ bonus.amount }}
          </li>
        </ul>
      </a-card>
      <a-button type="primary" @click="handleBonus" class="mt-3">Legg til bonus</a-button>
    </div>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'
import moment from 'moment'
import employeeFilter from '@/components/custom/payroll/filters'

export default {
  components: {
    employeeFilter,
  },
  data() {
    return {
      recruitments: [],
      recruitmentBonus: [],
      toBonus: [],
      columns: [
        {
          title: 'Recruit Name',
          dataIndex: 'RecruitName',
          key: 'RecruitName',
        },
        {
          title: 'Recruiter Name',
          dataIndex: 'RecruiterName',
          key: 'RecruiterName',
        },
        {
          title: 'Tip Name',
          dataIndex: 'TipName',
          key: 'TipName',
        },
        {
          title: '1 Month',
          dataIndex: 'Status',
          key: 'oneMonth',
          scopedSlots: { customRender: 'oneMonth' },
        },
        {
          title: 'Budget',
          dataIndex: 'Status',
          key: 'budget',
          scopedSlots: { customRender: 'budget' },
        },
        {
          title: '3 Month',
          dataIndex: 'Status',
          key: 'threeMonth',
          scopedSlots: { customRender: 'threeMonth' },
        },
        {
          title: 'Slett',
          dataIndex: 'delete',
          key: 'delete',
          scopedSlots: { customRender: 'delete' },
        },
      ],
    }
  },
  methods: {
    moment,
    async fetchRecruitments() {
      this.recruitments = []
      const snapshot = await firestore.collection('recruitment').get()
      snapshot.forEach((doc) => {
        const recruitment = doc.data()
        recruitment.id = doc.id
        this.recruitments.push(recruitment)
      })
    },
    async updateRecruitmentStatus(checked, record, key) {
      const recruitment = this.recruitments.find((item) => item.id === record)
      const recruitmentBonus = this.recruitmentBonus.find((item) => item.type === key)
      if (checked.target.checked) {
        const bonus = {
          type: 'bonus',
          amount: recruitmentBonus.amount,
          count: 1,
          employeeID: recruitment.TipID,
          employeeName: recruitment.TipName,
          recruitmentId: record,
          company: 'Skycall',
          department: recruitment.Department,
          comment: `${recruitmentBonus.comment} for verving av ${recruitment.RecruitName}`,
          key,
          status: 'unpaid',
        }
        this.toBonus.push(bonus)
      } else {
        const index = this.toBonus.findIndex((item) => item.recruitmentId === record)
        this.toBonus.splice(index, 1)
      }
      // try {
      //   const recruitmentRef = firestore.collection('recruitment').doc(status.RecruitID)
      //   await recruitmentRef.update({
      //     [`Status.${key}.paid`]: checked,
      //   })
      // } catch (error) {
      //   console.error('Error updating recruitment status:', error)
      // }
    },
    handleFilterChange(filter) {
      console.log('filter', filter)
    },
    handleBonus() {
      console.log('toBonus', this.toBonus)
      this.toBonus.forEach(async (item, index) => {
        try {
          const bonusRef = firestore.collection('payrollTransactions')
          item.createdDate = String(moment().format('YYYY-MM-DDTHH:mm'))
          item.period = String(moment().format('YYYYMM'))
          const bonusID = await bonusRef.add(item)
          console.log('bonusID', bonusID.id)
          this.updateRecruitment(item.recruitmentId, item.key, bonusID.id)
          openNotification('Bonus added', `Bonus added for ${item.employeeName}`)
          this.toBonus = []
          this.fetchRecruitments()
        } catch (error) {
          openNotification('Error adding bonus', `Error adding bonus for ${item.employeeName}`)
          console.error('Error adding bonus:', error)
        }
      })
    },
    async fetchRecruitmentBonus() {
      const snapshot = await firestore.collection('settings').doc('recruitment').collection('bonus').get()
      snapshot.forEach((doc) => {
        const bonus = doc.data()
        bonus.id = doc.id
        this.recruitmentBonus.push(bonus)
      })
    },
    updateRecruitment(recruitmentId, key, bonusId) {
      const i = this.recruitments.findIndex((item) => item.id === recruitmentId)
      const recruitmentStatus = this.recruitments[i].Status
      this.recruitments[i].Status[key].date = String(moment().format('YYYY-MM-DDTHH:mm'))
      this.recruitments[i].Status[key].bonusId = bonusId
      this.recruitments[i].Status[key].registeredBy = this.$store.state.user.employeeID
      console.log('recruitmentStatus', recruitmentStatus)
      firestore.collection('recruitment').doc(recruitmentId).update({
        Status: recruitmentStatus,
      })
    },
    deleteRecruitment(recruitmentId) {
      firestore.collection('recruitment').doc(recruitmentId).delete()
      this.fetchRecruitments()
    },
  },
  mounted() {
    this.fetchRecruitments()
    this.fetchRecruitmentBonus()
  },
}
</script>
