<template>
  <a-form layout="inline">
    <a-form-item label="Period">
      <a-month-picker v-model="period" @change="handleFilterChange" />
    </a-form-item>
    <a-form-item label="Employee">
      <a-select show-search v-model="selectedEmployee" @change="handleFilterChange" placeholder="Select an employee" style="width: 200px">
        <a-select-option v-for="user in users" :key="user.employeeID" :value="user.employeeID">{{ user.fullName }}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="Department">
      <a-select v-model="selectedDepartment" @change="handleFilterChange" placeholder="Select a department">
        <a-select-option v-for="department in departments" :key="department" :value="department">{{ department }}</a-select-option>
      </a-select>
    </a-form-item>
  </a-form>
</template>

<script>
import moment from 'moment'
import { firestore } from '@/services/firebase'

export default {
  data() {
    return {
      period: null,
      selectedEmployee: null,
      selectedDepartment: null,
      users: [],
      departments: [],
    }
  },
  methods: {
    handleFilterChange() {
      const period = this.period ? String(moment(this.period).format('YYYYMM')) : null
      this.$emit('filter-change', {
        period: period,
        selectedEmployee: this.selectedEmployee,
        selectedDepartment: this.selectedDepartment,
      })
    },
    async fetchUsers() {
      const usersSnapshot = await firestore.collection('users')
        .where('companies', 'array-contains-any', this.$store.state.user.companies)
        .orderBy('fullName')
        .get()
      usersSnapshot.forEach((doc) => {
        const user = {
          fullName: doc.data().fullName,
          department: doc.data().department,
          employeeID: doc.data().employeeID,
        }
        this.users.push(user)
      })
    },
    async fetchDepartments() {
      const departmentsSnapshot = await firestore
        .collection('settings')
        .doc('departments')
        .collection('departments')
        .where('company', 'in', this.$store.state.user.companies)
        .get()
      departmentsSnapshot.forEach((doc) => {
        this.departments.push(doc.data().name)
      })
    },
  },
  mounted() {
    this.fetchUsers()
    this.fetchDepartments()
  },
}
</script>
